import React from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import SearchMenu from './components/SearchMenu'

const SearchToolbar = () => {
  const {classes} = useLayout()
  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack h-100')}
      >
        <SearchMenu />
      </div>
      {/* end::Container */}
    </div>
  )
}

export default SearchToolbar
