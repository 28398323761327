import {Helmet} from 'react-helmet'

type Props = {
  title: string
  description: string
  keywords: string
}

const PageHead: React.FC<Props> = ({title, description, keywords}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords}></meta>
    </Helmet>
  )
}

export default PageHead
