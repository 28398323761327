import React, {useState} from 'react'
import ReactDom from 'react-dom'
import usePagination from '../../../hooks/use-pagination'
import {CollectionModel} from '../../../models/CollectionModel'
import {Pagination} from '../../../../_metronic/partials/index'
import Image from '../../../components/Image'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  requestConfig: any
}

const CollectionsTableComponent: React.FC<Props> = ({className, requestConfig}) => {
  const [collections, setCollections] = useState<Array<CollectionModel>>([])
  const [collectionsCopy, setCollectionsCopy] = useState<Array<CollectionModel>>([])

  const applyData = (data: any) => {
    setCollections(data.collections)
    setCollectionsCopy(data.collections)
  }

  const {currentPage, setCurrentPage, limit, total, isLoading, error} = usePagination(
    requestConfig,
    applyData
  )
  const TableBody = () => {
    return (
      <>
        {/* begin::Table body */}
        <tbody>
          {collections.map((collection) => (
            <tr key={collection.id}>
              <td>
                <div className='d-flex align-items-center'>
                  {collection.image && collection.image.thumbnail ? (
                    <div className='symbol symbol-45px me-5'>
                      <Image style={{objectFit: 'cover'}} src={collection.image.thumbnail} alt='' />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='w-250px d-flex justify-content-start flex-column'>
                    <Link
                      to={`/collection/${collection.legacyResourceId}`}
                      className='text-dark fw-bolder text-hover-primary fs-6'
                    >
                      {collection.title}
                    </Link>
                  </div>
                </div>
              </td>
              <td>
                {collection.bestSellers && collection.bestSellers.length > 0 && (
                  <a href={`/product/${collection.bestSellers[0].legacyResourceId}`}>
                    {collection.bestSellers[0].title}
                  </a>
                )}
              </td>
              <td>
                {collection.latestAdditions && collection.latestAdditions.length > 0 && (
                  <a href={`/product/${collection.latestAdditions[0].legacyResourceId}`}>
                    {collection.latestAdditions[0].title}
                  </a>
                )}
              </td>
              <td className='text-center'>
                <span className='badge badge-square badge-success'>{collection.productsCount}</span>
              </td>
            </tr>
          ))}
        </tbody>
        {/* end::Table body */}
      </>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Title</th>

                <th className='min-w-100px'>Best Seller</th>
                <th className='min-w-100px'>Latest Addition</th>
                <th className='min-w-100px text-center'>Formulas</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {!isLoading && !error ? (
              <TableBody />
            ) : (
              <tbody>
                <tr>
                  <td>
                    {error ? (
                      <div className='alert alert-danger'>{error}</div>
                    ) : (
                      <span className='mt-5'>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        <Pagination
          limit={limit}
          currentPage={currentPage}
          total={total}
          setCurrentPage={setCurrentPage}
          className={`mt-5`}
        />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default CollectionsTableComponent
