/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

type Props = {
  limit: number
  currentPage: number
  setCurrentPage: Function
  total: number
  className?: string
}

const Pagination: React.FC<Props> = ({limit, currentPage, total, setCurrentPage, className}) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(total / limit))

  useEffect(() => {
    setTotalPages(Math.ceil(total / limit))
  }, [total, limit])

  const pageClickHandler = (event: any, page: number) => {
    event.preventDefault()
    setCurrentPage(page)
  }

  const navigateHandler = (type: string) => {
    if (type === 'previous' && currentPage - 1 >= 0) {
      setCurrentPage((prevState: number) => prevState - 1)
    } else if (type === 'next' && currentPage + 1 < totalPages) {
      setCurrentPage((prevState: number) => prevState + 1)
    }
  }

  const getPages = (counter: number) => {
    let list = []
    for (let i = 0; i < counter; i++) {
      let className = 'page-item'
      if (i === currentPage) {
        className = 'page-item active'
      }
      list.push(
        <li key={i} className={className}>
          <a className='page-link' href='#' onClick={(event) => pageClickHandler(event, i)}>
            {i + 1}
          </a>
        </li>
      )
    }

    return list
  }
  return (
    <nav aria-label='Page navigation' className={className}>
      <ul className='pagination'>
        <li className='page-item'>
          <a
            className='page-link'
            href='#'
            aria-label='Previous'
            onClick={() => navigateHandler('previous')}
          >
            <span aria-hidden='true'>&laquo;</span>
            <span className='sr-only'>Previous</span>
          </a>
        </li>
        {getPages(totalPages)}
        <li className='page-item'>
          <a
            className='page-link'
            href='#'
            aria-label='Next'
            onClick={() => navigateHandler('next')}
          >
            <span aria-hidden='true'>&raquo;</span>
            <span className='sr-only'>Next</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export {Pagination}
