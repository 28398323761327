/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {usePageData} from '../../core/PageData'

const Toolbar1: FC = () => {
  const {shopLink} = usePageData()
  const {classes} = useLayout()

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />
        <div id='toolbar-search-container'></div>

        {/* begin::Actions */}
        {shopLink && (
          <div className='d-flex align-items-center py-1'>
            {/* begin::Button */}

            <a
              href={`${process.env.REACT_APP_CODEAGE_URL}${shopLink}`}
              target='_blank'
              rel='noreferrer'
              className='btn btn-sm btn-dark min-w-100px'
            >
              Shop
            </a>
            {/* end::Button */}
          </div>
        )}
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
