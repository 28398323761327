import {useState, useEffect, useMemo, useRef} from 'react'
import {debounce} from 'lodash'
import useQuery from './use-query'

const useSearch = (searchChangeHandler: Function) => {
  const {query, setHistoryParams} = useQuery()
  const isMounted = useRef(false)
  const [searchTerm, setSearchTerm] = useState(query.get('search') ? query.get('search') : '')

  const debouncedSearchHandler = useMemo(
    () =>
      debounce(function (event) {
        setSearchTerm(event.target.value)
      }, 300),
    []
  )

  //pass value directly instead of event
  const debouncedSearchHandlerValue = useMemo(
    () =>
      debounce(function (value) {
        setSearchTerm(value)
      }, 300),
    []
  )

  useEffect(() => {
    //dont run search on render or if search term is blank
    if (isMounted.current) {
      searchChangeHandler(searchTerm)
      if (searchTerm) {
        query.set('search', searchTerm)
      } else {
        query.delete('search')
      }
      setHistoryParams({search: query.toString()})
    } else {
      isMounted.current = true
    }
  }, [searchTerm])

  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [])

  return {debouncedSearchHandler, debouncedSearchHandlerValue, searchTerm, setSearchTerm}
}

export default useSearch
