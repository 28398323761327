import React from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../helpers'

type Props = {
  type: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  clickHandler?: any
  checkIsActive?: any
}

const checkIsActive = (type: string) => {}

const SearchMenuItem: React.FC<Props> = ({
  type,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  checkIsActive,
  clickHandler,
}) => {
  return (
    <div
      className={clsx('menu-item px-lg-2', {
        active: checkIsActive(type),
      })}
    >
      <button
        className={clsx('menu-link py-3 fw-bolder btn btn--link', {
          active: checkIsActive(type),
        })}
        onClick={() => {
          clickHandler(type)
        }}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className='menu-title text-dark'>{title}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </button>
    </div>
  )
}

export default SearchMenuItem
