import React, {useState, useCallback} from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import {useCancelToken} from './use-cancelToken'
import _ from 'lodash'

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const {cancelToken, isCancel} = useCancelToken()
  const [successMessage, setSuccessMessage] = useState('')

  const sendRequest = useCallback(
    async (
      requestConfig: AxiosRequestConfig,
      applyData: any,
      success = '',
      {errorCb, finallyCb} = {}
    ) => {
      setIsLoading(true)
      setError('')

      try {
        const response = await axios({
          cancelToken: cancelToken,
          method: requestConfig.method ?? 'GET',
          url: requestConfig.url,
          params: requestConfig.params ?? null,
          data: requestConfig.data ?? null,
        })

        if (response.status == 200) {
          const data = response.data
          applyData(data)
          setSuccessMessage(success)
        } else {
          setError(response.statusText)
        }
        setIsLoading(false)
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data)
        } else {
          setError(error.message)
        }
        setIsLoading(false)

        if (_.isFunction(errorCb)) errorCb(error)

        if (isCancel(error)) return
      } finally {
        if (_.isFunction(finallyCb)) finallyCb()
      }
    },
    []
  )

  return {
    isLoading,
    setIsLoading,
    error,
    sendRequest,
    cancelToken,
    successMessage,
  }
}

export default useHttp
