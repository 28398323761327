/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../helpers'
import useHttp from '../../../../app/hooks/use-http'
import {USER_URL} from '../../../helpers/ApiPaths'

type Props = {
  show: boolean
  handleClose: () => void
}

const Disable2FA: React.FC<Props> = ({show, handleClose}) => {
  const {error, sendRequest, successMessage} = useHttp()

  const validateOTP = () => {
    const applyData = () => {}
    sendRequest(
      {
        url: `${USER_URL}/disableOTP`,
        method: 'POST',
      },
      applyData,
      'Successfully Disabled 2FA'
    )
  }
  return (
    <Modal show={show} className='modal fade' id='kt_modal_disable_2FA' aria-hidden='true'>
      <div className='modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-5 text-center'>
              <h1 className='mb-3'>Disable 2FA</h1>

              <div className='text-muted fw-bold fs-5'>Are you sure you want to disable 2FA?</div>
            </div>
            {successMessage && (
              <div className='mb-lg-10 alert alert-success'>
                <div className='alert-text font-weight-bold'>{successMessage}</div>
              </div>
            )}
            {error && (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{error}</div>
              </div>
            )}

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button onClick={validateOTP} type='submit' className='btn btn-warning'>
                Disable 2FA
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {Disable2FA}
