import React, {FC, useEffect, useState} from 'react'
import {MenuInner} from './MenuInner'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

const Header: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [className, setClassName] = useState('')

  useEffect(() => {
    if (!isAuthorized) {
      setClassName('justify-content-center')
    }
  }, [isAuthorized])
  return (
    <div
      className='header-menu align-items-stretch w-100'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className={`menu  menu-column menu-lg-row  menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch ${className} justify-content-center`}
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <MenuInner />
      </div>
    </div>
  )
}

export {Header}
