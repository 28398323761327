import {KTSVG} from '../../../../_metronic/helpers'

const CategorySVGSelector = (props: any) => {
  let type = props.type
  type = type.toLowerCase()
  let svg = ''
  let color = ''
  switch (type) {
    case 'capsules':
      svg = '/media/icons/duotune/medicine/med002.svg'
      color = 'success'
      break
    case 'liquid':
      svg = '/media/icons/duotune/medicine/med005.svg'
      color = 'primary'
      break
    case 'powder':
      svg = '/media/icons/duotune/medicine/med008.svg'
      color = 'warning'
      break
    case 'gummies':
      svg = '/media/icons/duotune/abstract/abs024.svg'
      color = 'info'
      break
  }

  let className = `svg-icon-${color} svg-icon-1x me-2`
  if (svg !== '') {
    return <KTSVG path={svg} className={className} />
  } else {
    return <></>
  }
}

export default CategorySVGSelector
