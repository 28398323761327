import React, {useEffect, useState} from 'react'
import ProductsTableWidget from './components/ProductsTableWidget'
import {PageTitle} from '../../../_metronic/layout/core'
import {COLLECTION_REST_URL} from '../../../_metronic/helpers/ApiPaths'
import {useParams} from 'react-router-dom'
import {CollectionModel} from '../../models/CollectionModel'
import useHttp from '../../hooks/use-http'
import {Redirect, Switch} from 'react-router-dom'
import PageHead from '../../../_metronic/layout/components/PageHead'
import CardFullImageWidget from './components/CardFullImageWidget'
import ProductTextWidget from './components/ProductTextWidget'

type CollectionPageParams = {
  id: string
}

const CollectionPage: React.FC = () => {
  const [topRowClasses, setTopRowClasses] = useState('col-lg-6')

  const initVal = {
    id: '',
    handle: '',
    legacyResourceId: '',
    title: '',
  }
  const {id} = useParams<CollectionPageParams>()
  const [collection, setCollection] = useState<CollectionModel>(initVal)

  const requestConfig = {
    method: 'GET' as const,
    url: `${COLLECTION_REST_URL}/${id}`,
  }

  const {isLoading, error, cancelToken, sendRequest} = useHttp()

  useEffect(() => {
    const applyData = (data: CollectionModel) => {
      setCollection(data)
      if (!data.description || data.description === '') {
        setTopRowClasses('col-lg-12 mh-400px')
      }
    }

    sendRequest(requestConfig, applyData)
  }, [id, cancelToken])

  if (error) {
    return (
      <Switch>
        <Redirect to='/404' />
      </Switch>
    )
  }

  return (
    <>
      <PageHead
        title={`${collection.title} | Codeage Eternal | Wellness Platform`}
        description={`Discover ${collection.title} on the Codeage Eternal wellness platform and find data and insights using our wellness software.`}
        keywords={` ${collection.title}, wellness platform, wellness software, health platform, health software, vitamins, collagen`}
      />
      <PageTitle breadcrumbs={[]} shopLink={`/collections/${collection.handle}`}>
        {collection.title}
      </PageTitle>
      {/* begin::Row */}
      {(collection.image || collection.description) && (
        <div className='row gy-5 gx-xl-12 mb-5'>
          {collection.image && (
            <div className={topRowClasses}>
              <CardFullImageWidget
                img={collection.image.url}
                title={collection.title}
                className='card-stretch'
              />
            </div>
          )}
          {collection.description && (
            <div className={topRowClasses}>
              <ProductTextWidget
                title='Description'
                htmlTxt={collection.description}
                className='card-lg-stretch'
              />
            </div>
          )}
        </div>
      )}
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xl-12'>
          <ProductsTableWidget
            enableSearch={false}
            requestConfig={{method: 'GET', url: `${COLLECTION_REST_URL}/${id}/products`}}
            className="className='card-xxl-stretch mb-5 mb-xl-8'"
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default CollectionPage
