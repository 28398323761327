const API_URL = process.env.REACT_APP_API_URL

export const USER_URL = `${API_URL}/api/users`
export const NEW_SURVEY_TOPIC_POST = `${API_URL}/admin/product/surveyTopic`
export const PRODUCT_ADMIN_REST_URL = `${API_URL}/admin/product`
export const PRODUCT_ICON_ADMIN_REST_URL = `${API_URL}/admin/product/icons`
export const PRODUCT_SURVEY_REST_URL = `${API_URL}/admin/product/surveyTopic`
export const PRODUCT_REST_URL = `${API_URL}/api/product`
export const COLLECTION_REST_URL = `${API_URL}/api/collection`
export const COLLECTION_ADMIN_REST_URL = `${API_URL}/admin/collection`
export const TOPIC_ADMIN_REST_URL = `${API_URL}/admin/topic`
export const TOPIC_REST_URL = `${API_URL}/api/topic`
export const SURVEYQ_REST_URL = `${API_URL}/api/surveyQuestions`
export const INGREDIENTS_ADMIN_REST_URL = `${API_URL}/admin/ingredient`
export const INGREDIENTS_REST_URL = `${API_URL}/api/ingredient`
export const INGREDIENTS_FAMILY_ADMIN_REST_URL = `${API_URL}/admin/ingredientFamily`
export const INGREDIENTS_FAMILY_REST_URL = `${API_URL}/api/ingredientFamily`
export const SEARCH_URL = `${API_URL}/api/search`
