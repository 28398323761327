import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import CollectionIndexPage from '../pages/formula/CollectionIndexPage'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AdminIndexPage = lazy(() => import('../pages/admin/'))
  const ProductIndexPage = lazy(() => import('../pages/formula/ProductIndexPage'))
  const IngredientIndexPage = lazy(() => import('../pages/formula/IngredientIndexPage'))
  const InsightsIndexPage = lazy(() => import('../pages/insights/InsightsIndexPage'))
  const SearchPage = lazy(() => import('../pages/search/SearchPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/admin' component={AdminIndexPage} />
        <Route path='/ingredient' component={IngredientIndexPage} />
        <Route path='/product' component={ProductIndexPage} />
        <Route path='/insights' component={InsightsIndexPage} />
        <Route path='/collection' component={CollectionIndexPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/search' component={SearchPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
