import React from 'react'
import CollectionsTableComponent from './components/CollectionsTableComponent'
import {PageTitle} from '../../../_metronic/layout/core'
import {COLLECTION_REST_URL} from '../../../_metronic/helpers/ApiPaths'
import PageHead from '../../../_metronic/layout/components/PageHead'

const AllCollectionsPage: React.FC = () => {
  const requestConfig = {
    method: 'GET' as const,
    url: `${COLLECTION_REST_URL}/all`,
  }

  return (
    <>
      <PageHead
        title='Collections | Codeage Eternal | Nutrition & Supplements'
        description='Discover Codeage collections of products and find insights and data on the Codeage Eternal software platform.'
        keywords='Collections, nutrition, health software, wellness software, health platform, wellness platform, supplements'
      />
      <PageTitle breadcrumbs={[]}>Collections</PageTitle>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xl-12'>
          <CollectionsTableComponent
            requestConfig={requestConfig}
            className="className='card-xxl-stretch mb-5 mb-xl-8'"
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default AllCollectionsPage
