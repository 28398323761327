import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/users/me`
export const LOGIN_URL = `${API_URL}/api/auth`
export const REGISTER_URL = `${API_URL}/api/user`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string, token: string) {
  return axios.post(
    LOGIN_URL,
    {email, password, token},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

// Server should return AuthModel
export function register(email: string, name: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    name,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
