import React from 'react'
import SVG from 'react-inlinesvg'

type Props = {
  src: string
  alt?: string
  className?: string
  style?: any
  isSVG?: boolean
}

const bucket_base_url = process.env.REACT_APP_BUCKET_BASE_URL

const Image: React.FC<Props> = ({src, alt, className, style, isSVG = false}) => {
  if (src.substring(0, 7) === 'public/') {
    src = bucket_base_url + src
  }

  if (isSVG) {
    return (
      <span className={`svg-icon ${className}`}>
        <SVG src={src} />
      </span>
    )
  }
  return <img src={src} alt={alt} className={className} style={style} />
}

export default Image
