import React from 'react'
import {useLocation, useHistory} from 'react-router-dom'

type useHistoryParams = {
  search?: string
  pathname?: string
}

function useQuery() {
  const {search, pathname} = useLocation()
  const history = useHistory()

  const setHistoryParams = (params: useHistoryParams, {replace = false} = {}) => {
    const historyParams = {pathname, search}
    if (replace) {
      history.replace({...historyParams, ...params})
    } else {
      history.push({...historyParams, ...params})
    }
  }

  return {
    query: React.useMemo(() => new URLSearchParams(search), [search]),
    setHistoryParams,
  }
}

export default useQuery
