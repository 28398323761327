import React, {useEffect, useState} from 'react'
import Image from '../../../components/Image'

type Props = {
  img: string
  mobile_img?: string
  className?: string
  title: string
}

const CardFullImageWidget: React.FC<Props> = ({img, className, mobile_img, title}) => {
  const [additionalClasses, setAdditionalClasses] = useState('')
  useEffect(() => {
    if (mobile_img) {
      setAdditionalClasses('d-none d-md-block')
    }
  }, [mobile_img])
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <Image
        alt={title}
        src={img}
        className={`img-fluid w-100 h-100 card-img-top ${additionalClasses} rounded`}
        style={{objectFit: 'cover'}}
      />

      {mobile_img && (
        <Image alt={title} src={mobile_img} className='img-fluid d-md-none card-img-top' />
      )}
    </div>
  )
}

export default CardFullImageWidget
