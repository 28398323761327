import {useRef, useEffect, useCallback} from 'react'
import axios, {CancelTokenSource} from 'axios'

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelToken: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 */
export const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source())
  const isCancel = axios.isCancel

  useEffect(
    () => () => {
      axiosSource.current.cancel()
    },
    []
  )

  return {cancelToken: axiosSource.current.token, isCancel}
}
