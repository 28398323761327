import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AllCollectionsPage from './AllCollectionsPage'
import CollectionPage from './CollectionPage'

const CollectionIndexPage: React.FC = () => {
  return (
    <Switch>
      <Route path='/collection/all'>
        <AllCollectionsPage />
      </Route>
      <Route path='/collection/:id'>
        <CollectionPage />
      </Route>
      <Redirect from='/collection' exact={true} to='/collection/all' />
      <Redirect to='/collection/all' />
    </Switch>
  )
}

export default CollectionIndexPage
