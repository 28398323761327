import {Route, Switch} from 'react-router-dom'
import {Toolbar1} from './Toolbar1'
import SearchToolbar from './SearchToolbar'

const Toolbar = () => {
  return (
    <Switch>
      <Route path='/search'>
        <SearchToolbar />
      </Route>
      <Route path='/'>
        <Toolbar1 />
      </Route>
    </Switch>
  )
}

export {Toolbar}
