import {useState, useEffect, useCallback} from 'react'
import {useHistory, useLocation} from 'react-router'
import useHttp from './use-http'

const usePagination = (requestConfig: any, processData: Function) => {
  const {pathname, search} = useLocation()
  const history = useHistory()
  const currentSearch = new URLSearchParams(search)

  const [currentPage, setCurrentPage] = useState(parseInt(currentSearch.get('page') ?? '0'))

  let defaultLimit = '100'
  if (requestConfig && requestConfig.params && requestConfig.params.limit) {
    defaultLimit = String(requestConfig.params.limit)
  }
  const [limit, setLimit] = useState(parseInt(currentSearch.get('limit') ?? defaultLimit))

  const [total, setTotal] = useState(0)

  const {isLoading, error, sendRequest, cancelToken} = useHttp()

  useEffect(() => {
    const applyPagination = (data: any) => {
      setTotal(data.totalCount)
      processData(data)
    }
    sendRequest(
      {
        ...requestConfig,
        params: {
          limit,
          page: currentPage,
          ...requestConfig.params,
        },
      },
      applyPagination
    )
    currentSearch.set('page', currentPage.toString())
    history.push({pathname: pathname, search: currentSearch.toString()})
  }, [limit, currentPage, cancelToken])

  return {
    currentPage,
    setCurrentPage,
    setLimit,
    setTotal,
    limit,
    total,
    error,
    isLoading,
  }
}

export default usePagination
