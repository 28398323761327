/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../helpers'
import useHttp from '../../../../app/hooks/use-http'
import {USER_URL} from '../../../helpers/ApiPaths'

type Props = {
  show: boolean
  handleClose: () => void
  qrcodeUrl: string
}

const Enable2FA: React.FC<Props> = ({show, handleClose, qrcodeUrl}) => {
  const {error, sendRequest, successMessage} = useHttp()
  const [otpToken, setotpToken] = useState('')

  const validateOTP = () => {
    const applyData = () => {}
    sendRequest(
      {
        url: `${USER_URL}/verifyOTP`,
        method: 'POST',
        data: {
          token: otpToken,
        },
      },
      applyData,
      'Successfully Enabled 2FA'
    )
  }
  return (
    <Modal
      show={show}
      className='modal fade'
      id='kt_modal_two_factor_authentication'
      aria-hidden='true'
    >
      <div className='modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-5 text-center'>
              <h1 className='mb-3'>Add Authenticator</h1>

              <div className='text-muted fw-bold fs-5'>Scan the QR Code</div>

              <img alt='Qr code' src={qrcodeUrl} />

              {/* begin::Form group */}
              <div className='d-flex flex-center '>
                <div className='fv-row mw-50'>
                  <label className='form-label fs-6 fw-bolder text-dark'>OTP Token:</label>
                  <input
                    placeholder='OTP'
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    name='token'
                    value={otpToken}
                    onChange={(e) => setotpToken(e.target.value)}
                  />
                </div>
              </div>
              {/* end::Form group */}
            </div>
            {successMessage && (
              <div className='mb-lg-10 alert alert-success'>
                <div className='alert-text font-weight-bold'>{successMessage}</div>
              </div>
            )}
            {error && (
              <div className='mb-lg-10 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{error}</div>
              </div>
            )}

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button onClick={validateOTP} type='submit' className='btn btn-primary'>
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {Enable2FA}
