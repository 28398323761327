import React, {useState} from 'react'
import useSearch from '../../../../app/hooks/use-search'
import {useLocation} from 'react-router-dom'
import useQuery from '../../../../app/hooks/use-query'

const HeaderSearch = () => {
  const location = useLocation()
  const {query, setHistoryParams} = useQuery()
  const {debouncedSearchHandlerValue, searchTerm: debouncedSearchTerm} = useSearch(() => {})
  const [searchTerm, setSearchTerm] = useState(debouncedSearchTerm ?? '')

  const onSubmitFunction = (event: any) => {
    event.preventDefault()
    if (location.pathname !== '/search') {
      query.set('search', searchTerm)
      setHistoryParams({pathname: '/search', search: query.toString()})
    } else {
      debouncedSearchHandlerValue(searchTerm)
    }
  }
  return (
    <>
      <div className='d-flex d-none  align-items-center justify-content-md-end justify-content-lg-start flex-fill d-lg-flex'>
        <form className='form-inline' onSubmit={onSubmitFunction}>
          <div className='input-group rounded bg-secondary'>
            <input
              type='text'
              className='form-control bg-secondary  border-end-0 shadow-none border border-dark'
              placeholder='Search...'
              aria-label='search'
              aria-describedby='search'
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value)
              }}
            />
            <button type='submit' className='input-group-text bg-transparent border border-dark'>
              <i className='fas fa-search'></i>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default HeaderSearch
