import React from 'react'

type Props = {
  title: string
  htmlTxt: string
  className?: string
}

const ProductTextWidget: React.FC<Props> = ({title, htmlTxt, className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>{title}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5 '>
        <div className='mh-400px scroll-y'>
          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Text */}
            <div
              className='d-flex flex-column fs-6'
              dangerouslySetInnerHTML={{__html: htmlTxt}}
            ></div>
            {/* end::Text */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default ProductTextWidget
