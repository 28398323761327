import React, {useState} from 'react'
import {formatMoney} from '../../../../_metronic/helpers/MoneyHelper'
import {Pagination} from '../../../../_metronic/partials/index'
import usePagination from '../../../hooks/use-pagination'
import {extractTag} from '../../../helpers/utils'
import {Link} from 'react-router-dom'
import CategorySVGSelector from './CategorySVGSelector'

type Props = {
  className: string
  requestConfig: any
  enableSearch?: boolean
}

const ProductsTableWidget: React.FC<Props> = ({className, requestConfig, enableSearch = true}) => {
  const [products, setProducts] = useState([])
  const [productsCopy, setproductsCopy] = useState([])

  const applyData = (data: any) => {
    let products = data.products
    products.map((product: any) => {
      product.format = extractTag('format:', product.tags, ':')
      product.category = extractTag('mainCollection:', product.tags, ':')
      product.label = extractTag('label_', product.tags, '_')

      if (product.totalInventory > 0) {
        product.inStock = true
      } else {
        product.inStock = false
      }
      return product
    })

    setProducts(data.products)
    setproductsCopy(data.products)
  }

  const {currentPage, setCurrentPage, limit, total, isLoading, error} = usePagination(
    requestConfig,
    applyData
  )

  const TableBody = () => {
    return (
      <>
        {/* begin::Table body */}
        <tbody>
          {products.map((product: any) => (
            <tr key={product.id}>
              <td>
                <div className='d-flex align-items-center'>
                  {product.featuredImage && product.featuredImage.thumbnail ? (
                    <div className='symbol symbol-45px me-5'>
                      <img src={product.featuredImage.thumbnail} alt='' />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='w-250px d-flex justify-content-start flex-column'>
                    <Link
                      to={`/product/${product.legacyResourceId}`}
                      className='text-dark fw-bolder text-hover-primary fs-6'
                    >
                      {product.title}
                    </Link>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <CategorySVGSelector type={product.format} />
                  <span className='text-dark fw-bold d-block fs-7'>{product.format}</span>
                </div>
              </td>

              <td>
                <span className='text-dark fw-bold d-block fs-7'>
                  {product.priceRangeV2.maxVariantPrice.amount !==
                  product.priceRangeV2.minVariantPrice.amount
                    ? `from ${formatMoney(product.priceRangeV2.minVariantPrice.amount)}`
                    : formatMoney(product.priceRangeV2.minVariantPrice.amount)}
                </span>
              </td>
              <td>
                <span className='text-dark fw-bold d-block fs-7'>{product.label}</span>
              </td>
              <td>
                {product.inStock ? (
                  <span className='fw-bold d-block fs-7 text-success'>Available</span>
                ) : (
                  <span className='fw-bold d-block fs-7 text-danger'>Not Available</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        {/* end::Table body */}
      </>
    )
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Title</th>
                <th className='min-w-100px'>Format</th>
                <th className='min-w-100px'>Price</th>
                <th className='min-w-100px'>Tag</th>
                <th className='min-w-100px'>Stock</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {!isLoading && !error ? (
              <TableBody />
            ) : (
              <tbody>
                <tr>
                  <td>
                    {error ? (
                      <div className='alert alert-danger'>{error}</div>
                    ) : (
                      <span className='mt-5'>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        <Pagination
          limit={limit}
          currentPage={currentPage}
          total={total}
          setCurrentPage={setCurrentPage}
          className={`mt-5`}
        />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ProductsTableWidget
