const extractTag = (prefix: string, tagsArr: Array<string>, separator: string) => {
  let tag = tagsArr.find((tag: string) => tag.includes(prefix))
  if (tag) {
    return tag.split(separator)[1]
  } else {
    return ''
  }
}

const extractMetafield = (metafieldsArr: Array<any>, namespace: string, key: string) => {
  let metafield = null
  if (metafieldsArr !== undefined && metafieldsArr !== null) {
    metafield = metafieldsArr.find((metafield) => {
      return metafield.namespace === namespace && metafield.key === key
    })
  }

  if (metafield) {
    return metafield.value
  } else {
    return null
  }
}

export {extractTag, extractMetafield}
