import SearchMenuItem from './SearchMenuItem'
import useQuery from '../../../../../app/hooks/use-query'
import {useHistory} from 'react-router-dom'
import './SearchMenu.css'
import React, {useState, useEffect} from 'react'

const SearchMenu = () => {
  const {query, setHistoryParams} = useQuery()
  const history = useHistory()
  const [currentType, setCurrentType] = useState<string | null>(null)

  const menuItemClickHandler = (type: string) => {
    query.set('type', type)
    setHistoryParams({search: query.toString()})
    //history.push({search: query.toString()})
  }

  const checkIsActive = (type: string) => {
    if (currentType === 'survey_questions') {
      return type === 'insights' || type === currentType
    } else {
      return type === currentType
    }
  }

  useEffect(() => {
    if (!query.has('type')) {
      setCurrentType('all')
      query.set('type', 'all')
      history.replace({search: query.toString()})
    } else {
      setCurrentType(query.get('type'))
    }
  }, [query, history])

  return (
    <div
      className={`menu  menu-row overflow-auto menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch h-100`}
      id='kt_search_menu'
      data-kt-menu='true'
    >
      <SearchMenuItem
        checkIsActive={checkIsActive}
        title='All'
        type='all'
        icon='/media/svg/dashboard-icons/Products.svg'
        clickHandler={menuItemClickHandler}
      />
      <SearchMenuItem
        checkIsActive={checkIsActive}
        title='Products'
        type='products'
        icon='/media/svg/dashboard-icons/Products.svg'
        clickHandler={menuItemClickHandler}
      />
      <SearchMenuItem
        checkIsActive={checkIsActive}
        title='Collections'
        type='collections'
        icon='/media/svg/dashboard-icons/Collections.svg'
        clickHandler={menuItemClickHandler}
      />
      <SearchMenuItem
        checkIsActive={checkIsActive}
        title='Ingredients'
        type='ingredients'
        icon='/media/svg/dashboard-icons/Ingredients.svg'
        clickHandler={menuItemClickHandler}
      />
      <SearchMenuItem
        checkIsActive={checkIsActive}
        title='Insights'
        type='insights'
        icon='/media/svg/dashboard-icons/LatestInsights.svg'
        clickHandler={menuItemClickHandler}
      />
    </div>
  )
}

export default SearchMenu
